.slogan-color1 {
    color: black
}

.slogan-color2 {
    color: #e50101
}

.slogan-font {
    font-weight: bold;
}

.slogan {
    text-align: center;
}

@media (min-width: 768px) {
    .slogan {
        font-size: xx-large
    }
}

@media (max-width: 768px) {
    .slogan {
        font-size: large;
    }
}

.fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}