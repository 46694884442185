.footer-text {
    font-size: small !important;
    z-index: 3;
    color: gray;
}

.footer-icon {
    font-size: smaller !important;
    z-index: 3;
    color: gray !important;
    text-decoration: none !important;
}

.footer-icon:hover {
    color: gray !important;
}


@media screen and (max-width: 576px) {
    .footer-text {
        font-size: 0.8em !important;
    }

}