.sub-footer-uoft-logo {
    width: 200px;
}

.sub-footer-uht-logo {
    width: 220px;
}

.sub-footer-cghr-logo {
    width: 260px
}

@media (max-width: 768px) {
    .sub-footer-uoft-logo {
        width: 150px;
    }

    .sub-footer-uht-logo {
        width: 170px;
    }

    .sub-footer-cghr-logo {
        width: 240px;
    }

}

.fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}