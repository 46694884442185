.dataInfo-title {
    font-Size: 1.3em;
    font-Weight: bold;
    margin-bottom: 8px;
}

.dataInfo-publish-date {
    font-size: medium;
    color: #6c757d
}

.dataInfo-contact {
    font-size: medium;
    color: #6c757d;
    text-decoration: none;
}

.dataInfo-contact:hover {
    font-size: medium;
    color: #6c757d;
    text-decoration: none;
}

.dataInfo-md-content {
    font-size: medium;
}

@media screen and (max-width: 300px) {
    .dataInfo-publish-date {
        font-size: 0.8em;
    }

    .dataInfo-contact {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 768px) {

    .dataInfo-title {
        font-Size: 1.1em;
    }

    .datasetInfo-md-content {
        font-size: 1em;
    }
}