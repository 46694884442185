.info-display-card {
    margin: 0px 0px 0px 0px !important;
    border: none !important;
    border-radius: 5px !important;
    margin-bottom: 0px !important;
    transition: transform 0.2s ease !important;
    width: 90% !important;
    text-align: center !important;
}

.info-display-card-text {
    text-align: center !important;
}

.data-listing-card {
    position: relative !important;
    /*border-bottom: 8px solid rgb(73, 73, 73);*/
    /*border-radius: 5px;*/
    border-radius: 12px !important;
    margin-bottom: 20px !important;
    margin-top: 5px !important;
    transition: transform 0.2s ease !important;
    max-width: 100% !important;
    padding: 12px !important;
}

.custom-card-text {
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: start !important;
}

.data-listing-card-body {
    padding: 25px 25px 25px 25px !important;
    position: relative !important;
    z-index: 1 !important;        
}

@media (min-width: 768px) {
    .data-listing-card-title {
        font-size: x-large !important;        
    }

    .dataset-listing-card {
        /*background-size: 25% auto;*/
        font-size: medium !important;
    }

    .data-listing-card:hover {
        transform: scale(1.05) !important;
    }

    .data-listing-card::before {
        border-radius: 10px !important;
        z-index: 0 !important;
        content: '' !important;
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        background-color: rgba(255, 255, 255, 0.95) !important;
    }
}

@media (max-width: 767px) {
    .data-listing-card {
        font-size: medium !important;
    }

    .data-listing-card-title {
        font-size: large !important;                
    }
}

@media (min-width: 768px) {
    .button-on-card {
        width: 12rem !important;
        border-radius: 1rem !important;
        background-color: #343A40 !important;
    }
}

.form-check-my-dataset {
    color: #a52323 !important;
}

.form-check-my-dataset .form-check-input {
    margin-right: 0.5cap;    
    border-color: #a52323 !important;
}

.form-check-my-dataset .form-check-input:checked {
    margin-right: 0.5cap;
    background-color: #a52323 !important;
    border-color: #a52323 !important;
}

.om-dataset-sort-order-button {
    padding: 0rem 0.7rem;
    height: 2.3rem;
    width: auto;
    border-right: 2px solid rgb(219, 219, 219) !important;    
    border-radius: .9rem 0rem 0rem .9rem !important;
    color: white !important;
    background-color: #212529 !important;    
    border: none !important;
}

.om-dataset-sort-order-button:disabled {    
    color: grey !important;
    background-color:#212529 !important;    
    opacity: 1 !important;
    border-right: 2px solid rgb(219, 219, 219) !important;
    border: none !important;
}

.om-dataset-sort-button {
    padding: 0rem 0rem;
    height: 2.3rem;
    width: 3rem;
    border-radius: 0rem .9rem .9rem 0rem !important;    
    color: white;    
    background-color: black;
    border: none !important;
}

.om-dataset-sort-button:disabled {        
    color: grey !important;
    background-color:#212529 !important;    
    opacity: 1 !important;  
    border: none !important;  
}

.om-dataset-sorting-options-user {    
    color: rgb(0, 0, 0) !important;
}

.om-dataset-sorting-options-admin {    
    color: #a52323 !important;
}


/*@media (max-width: 767px) {
    .button-on-card {
        width: 100% !important;
        border-radius: 1rem !important;
    }
}*/