@media (min-width: 768px) {
    .explore-dataset {
        font-size: 1.1rem !important;
        /*margin: 12px;*/
        margin: 1rem !important;
        border-radius: 2rem !important;
        padding: 0.1rem;
        /*padding-left: 10px;
        padding-top: 4px;
        padding-bottom: 4px;*/
        /*m-3 ps-3 pt-2 pb-2*/
        background-color: #000000 !important;
        border: none !important;
    }
}

@media (max-width: 768px) {
    .explore-dataset {
        font-size: 0.8rem !important;
        /*margin: 10px;
        padding-left: 6px;
        padding-right: 4px;
        padding-top: 2px;
        padding-bottom: 2px;*/
        background: #000000 !important;
        margin: 0.5rem !important;
        border-radius: 2.5rem !important;
        padding: 0.01rem !important;
    }


}