.datasetInfo-title {
    font-Size: 1.3em;
    font-Weight: bold;
    margin-bottom: 8px;
}

.datasetInfo-publish-date {
    font-size: medium;
    color: #6c757d
}

.datasetInfo-contact {
    font-size: medium;
    color: #6c757d;
    text-decoration: none;
}

.datasetInfo-contact:hover {
    font-size: medium;
    color: #6c757d;
    text-decoration: none;
}

.datasetInfo-md-content {
    font-size: medium;
}


@media screen and (max-width: 300px) {
    .datasetInfo-publish-date {
        font-size: 0.8em;
    }

    .datasetInfo-contact {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 768px) {

    .datasetInfo-title {
        font-Size: 1.1em;
    }

    .datasetInfo-md-content {
        font-size: 1em;
    }
}