.animated-logo-main {
    width: 400px;
    height: auto;
}

@media (max-width: 767px) {
    .animated-logo-main {
        width: 250px;
        height: auto;
    }
}

.fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}