.om-header-logo {
    width: 150px;
    height: auto;
    cursor: pointer;
}

.om-header-search {
    border-radius: 15px !important;
    margin-right: 8px;
    width: 30px;
    height: 40px;
}

.om-header-search::placeholder {
    font-size: large;
}

.om-header-search-button {
    padding: 0px 0px;
    height: 38px;
    width: 38px;
    border-radius: 50% !important;
    color: white;
    background-color: black;
    border: none;
}

.om-header-search-button:hover {
    background-color: #a52323;
    border-color: #a52323;
}

.om-header-search-button:focus {
    background-color: #a52323;
    border-color: #a52323;
}

.om-header-search-response-text {
    font-size: small;
}

.om-header-search-icon {
    height: 16px
}

.download-cart-icon {
    font-size: 1.3rem;
    margin: 5px 5px 6px 5px;
}

.download-cart-item {
    font-size: 1.1rem;
    margin: 1px 0px 0px 0px;
}

.profile-icon {
    font-size: 1.5rem;
    margin: 0px 0px 0px 0px;
}

.profile-name {
    display: inline;
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 5px;
}


@media screen and (max-width: 576px) {
    .download-cart-icon {
        font-size: 1rem;
    }

    .download-cart-item {
        font-size: 0.8rem;
        padding: 0px 0px 1px 0px;
    }

    .profile-icon {
        font-size: 1.1rem;
    }

    .profile-name {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .om-header-logo {
        width: 80px;
        height: auto;
        cursor: "pointer";
        padding: 2px 2px 2px 8px;
    }

    .om-header-search-response-text {
        font-size: x-small;
    }

    .om-header-search {
        border-radius: 15px !important;
        margin-right: 8px;
        width: 30px;
        height: 28px;
    }

    .om-header-search-button {
        padding: 2px;
        height: 28px;
        width: 28px;
        border-radius: 50% !important;
    }

    .om-header-search::placeholder {
        font-size: smaller;
    }

    .om-header-search-icon {
        height: 14px
    }


}

.user-profile-dropdown a {
    color: rgb(0, 0, 0) !important;
}

.user-profile-dropdown a:hover {
    color: rgb(0, 0, 0) !important;
}

.user-profile-dropdown a:active {
    color: rgb(0, 0, 0) !important;
    background-color: transparent;
}