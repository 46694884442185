@media only screen and (max-width: 768px) {

    /* Adjust for small screens */
    .aboutOmpImageContainer {
        height: 10vh !important;
        overflow: hidden;
    }

    .aboutOmpImage {
        height: auto;
        object-fit: cover;
        width: 100%;
        object-position: 0px -40px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {

    /* Adjust for medium screens */
    .aboutOmpImageContainer {
        height: 25vh;
        overflow: hidden
    }

    .aboutOmpImage {
        height: auto;
        object-fit: cover;
        width: 100%;
        object-position: 0px -130px;
    }
}

@media only screen and (min-width: 1025px) {
    .aboutOmpImageContainer {
        height: 20vh;
        overflow: hidden
    }

    /* Adjust for large screens */
    .aboutOmpImage {
        height: auto;
        object-fit: cover;
        width: 100%;
        object-position: 0px -140px;
    }
}