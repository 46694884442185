.admin-portal-overlay-indicator {
  background-color: rgba(165, 35, 35, 0.9);
  color: white;
  z-index: 3;
  top: 50%;
  font-size: 16px;
  border-radius: 6px 0px 0px 6px;
  position: fixed;
  right: 0px;
  transform: translateY(-50%);
  border: none;
  outline: none;
  white-space: nowrap;
  letter-spacing: 0.05rem;
  font-weight: bold;
}

.admin-portal-overlay-indicator span {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-decoration: none;
}

@media screen and (max-height: 100vh) {
  .admin-portal-overlay-indicator {
    position: sticky;
    float: right;
    margin-top: calc(50vh - 50%);
    transform: translateY(-50%);
    padding: 12px 10px 8px 10px;
  }
}

/* Handling for medium screen*/
@media screen and (max-height: 100vh) and (max-width: 1200px) {
  .admin-portal-overlay-indicator {
    position: sticky;
    float: right;
    margin-top: calc(29vh - 50%);
    transform: translateY(-50%);
    /*padding: 8px 6px 8px 6px;*/
    padding: 12px 10px 8px 10px;
  }
}

/* Handling for small screen*/
@media screen and (max-height: 100vh) and (max-width: 767px) {
  .admin-portal-overlay-indicator {
    position: sticky;
    float: right;
    margin-top: calc(5vh - 50%);
    transform: translateY(-50%);
    font-size: 13px;
  }

  @supports (-webkit-touch-callout: none) {

    /* CSS for iPhone */
    .admin-portal-overlay-indicator {
      padding: 9px 22px 6px 6px;
    }
  }

  @supports not (-webkit-touch-callout: none) {

    /* CSS for other devices */
    .admin-portal-overlay-indicator {
      padding: 10px 3px 5px 5px;
    }
  }
}

/*.admin-portal-overlay-indicator {
  z-index: 3;
  font-weight: bolder;
  position: fixed;
  left: 25%;
  padding: 5px;
  background-color: rgba(165, 35, 35, 0.9);
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  height: 30px;
  width: 50%;
  border-radius: 5px;
}*/