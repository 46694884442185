.svg-font-color svg>path {
  fill: var(--ifm-font-color-base);
}

.queryBuilder {
  /*min-width: 420px;*/
  overflow-x: auto;
}

.ruleGroup {
  background-color: white;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(126, 126, 126);
}

.queryBuilder .ruleGroup-combinators {
  width: 10%;
  text-align: center;
}

.queryBuilder .rule-fields {
  width: 50%;
}

.queryBuilder .rule-operators {
  max-width: fit-content;
  text-align: start;
}

.queryBuilder .rule-value {
  width: 35%;
}

.ruleGroup-notToggle {
  color: #000000;
}

.case-sensitive-toggle {
  color: #000000;
  position: relative;
}

.qb-remove-rule-button {
  color: #ffffff;
  border: none;
  background-color: #343A40;
  border-radius: 2rem;
}

/* big screen*/
@media (min-width: 768px) {

  .qb-add-group-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 3rem !important;
    width: 5rem;
  }

  .qb-add-rule-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 2rem !important;
    width: 5rem;
  }

  .qb-clear-button {
    border-radius: 2rem !important;
    width: 13rem;
    border-radius: 1rem;
    color: #000000 !important;
  }

}

/* small screen*/
@media (max-width: 992px) {
  /*.queryBuilder .ruleGroup-combinators {
    
  }*/

  /*.queryBuilder .rule-fields {
    width: 30%;
  }*/

  /*.queryBuilder .rule-operators {
    width: 30%;
  }*/

  /*.queryBuilder .rule-value {
    width: 40%;
  }*/

  .ruleGroup {
    background-color: white;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(126, 126, 126);
    width: fit-content;
  }

  .qb-clear-button {
    border-radius: 2rem !important;
    width: 100% !important;
    color: #000000 !important;
  }

  .qb-add-group-button {
    background-color: #000000;
    color: white;
    border: solid 1px white !important;
    border-radius: 3rem !important;
  }

  .qb-add-rule-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 2rem !important;
    width: 5rem;
  }
}

.queryBuilder .rule-value .rule-value-list-item {
  width: 50%;
}

.qb-clear-button {
  border-radius: 2rem !important;
}

.qb-clear-button:hover {
  background-color: initial !important;
  color: initial !important;
}

/*
.rule.queryBuilder-invalid .rule-value {
  background-color: rgba(255, 221, 221);
}

.rule.queryBuilder-invalid .rule-value-list-item {
  background-color: rgba(255, 221, 221);
}*/

/* Default CSS*/
.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid {
  background-color: rgba(102, 51, 153, 0.4);
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid .ruleGroup-addRule {
  font-weight: bold !important;
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid>.ruleGroup-header::after {
  content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
  color: white;
}

.validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
  background-color: rgba(102, 51, 153, 0.4);
}

.validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
  color: #47246b;
}

html[data-theme="dark"] .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
  color: #945ec9;
}

.justifiedLayout .queryBuilder .ruleGroup-addGroup+button.ruleGroup-cloneGroup,
.justifiedLayout .queryBuilder .ruleGroup-addGroup+button.ruleGroup-lock,
.justifiedLayout .queryBuilder .ruleGroup-addGroup+button.ruleGroup-remove,
.justifiedLayout .queryBuilder .rule-operators+button.rule-cloneRule,
.justifiedLayout .queryBuilder .rule-operators+button.rule-lock,
.justifiedLayout .queryBuilder .rule-operators+button.rule-remove,
.justifiedLayout .queryBuilder .rule-value+button.rule-cloneRule,
.justifiedLayout .queryBuilder .rule-value+button.rule-lock,
.justifiedLayout .queryBuilder .rule-value+button.rule-remove,
.justifiedLayout .queryBuilder .control+button.rule-cloneRule,
.justifiedLayout .queryBuilder .control+button.rule-lock,
.justifiedLayout .queryBuilder .control+button.rule-remove,
.justifiedLayout .queryBuilder .chakra-select__wrapper+button.rule-cloneRule,
.justifiedLayout .queryBuilder .chakra-select__wrapper+button.rule-lock,
.justifiedLayout .queryBuilder .chakra-select__wrapper+button.rule-remove {
  margin-left: auto;
}