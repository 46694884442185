/***********************/
/* Page control button */
/***********************/
/* Styles for the icon and button container */
.icon-button {
    display: inline-block;
    cursor: pointer;
}

/* Styles for the icon container */
.icon-container {
    display: inline-block;
    /* Add more styles here to customize the icon appearance */
}

/* Styles for the icon */
.icon {
    color: #ff0000;
    /* Set your desired icon color */
}

/* Styles for the button text */
.button-text {
    display: inline-block;
    vertical-align: middle;
    /* Add more styles here to customize the button text appearance */
}

/* Hover effect - change background or text color on hover */
.icon-button:hover {
    /*background-color: #ffffff;*/
    color: #ff0000;
}


/* Hover effect - Show tooltip on hover */
.icon-container:hover .tooltip {
    opacity: 1;
}

@media (max-width: 767px) {
    .icon-button {
        display: inline-block;
        cursor: pointer;
        /*font-size: smaller;*/
    }
}

/***************************/
/* Data table in map popup */
/***************************/
.spatial-preview-table {
    border-collapse: separate;
    border-spacing: 0 6px;
    padding-left: 1px;
    padding-right: 1px;
}

.spatial-preview-table th {
    padding: 0.1em 0.5em;
    border-spacing: 0 0px;
    /*background: #A52323;*/
    border-bottom: solid #A52323 2px;
    /*border-top: solid #A52323 1px;
    border-left: solid #A52323 1px;
    border-right: solid #A52323 1px;*/
    color: rgb(0, 0, 0);
    text-align: center;
    /*border-radius: 5px;*/
    font-weight: bold;
}

.spatial-preview-table tbody tr td {
    padding: 0.2em 0.3em;
    color: #5a5a5a;
    vertical-align: middle;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    font-size: small;
}

.spatial-preview-table tbody tr td:nth-child(1) {
    border-radius: 5px 0px 0px 5px;
    border-left: 1px solid #e8e8e8;
}

.spatial-preview-table tbody tr td:nth-last-child(1) {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #e8e8e8;
}

.spatial-preview-table thead tr th:nth-child(1) {
    border-radius: 5px 0px 0px 5px;
}

.spatial-preview-table thead tr th:nth-last-child(1) {
    border-radius: 0 5px 5px 0;
}

@media (max-width: 768px) {
    .spatial-preview-table th {
        padding: 0.5em 0em;
        border-spacing: 0 0px;
        background: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 5px;
        font-weight: bold;
    }

    .spatial-preview-table tbody tr td {
        padding: 0.2em 0.2em;
        color: #5a5a5a;
        vertical-align: middle;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        text-align: center;
        font-size: smaller;
    }
}

/**************/
/* Data table */
/**************/
.table-data-viewing-textbox {
    height: 1.3em;
    padding: 10px 10px 10px 10px;
    margin: 4px 2px 2px 2px;
    font-size: small;
}

.table-data-viewing-textbox::placeholder {
    opacity: 0.6;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
}

/* Original CSS for data table*/
.table-data-viewing {
    border-collapse: separate !important;
    border-spacing: 0 0.9rem !important;
    padding-left: 2px !important;
    padding-right: 6px !important;
}

.table-data-viewing thead {
    border-spacing: 0 0px !important;
    background: rgb(0, 0, 0) !important;
    color: white !important;
    text-align: center !important;
}

.table-data-viewing tbody tr td:nth-child(1) {
    border-radius: 15px 0px 0px 15px;
    border-left: 1px solid #e8e8e8;
}

.table-data-viewing tbody tr td:nth-last-child(1) {
    border-radius: 0 15px 15px 0;
    border-right: 1px solid #e8e8e8;
}

.table-data-viewing tbody tr td {
    padding: 22px 20px 22px 20px;
    color: #5a5a5a;
    vertical-align: middle;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    box-shadow: 0.19rem 0.2rem 0.3rem rgba(0, 0, 0, 0.05);
}

.table-data-viewing thead tr th:nth-child(1) {
    border-radius: 15px 0px 0px 15px;
}

.table-data-viewing thead tr th:nth-last-child(1) {
    border-radius: 0 15px 15px 0;
}

.table-data-viewing thead tr th {
    padding: 1rem !important;
    background-color: black;
    color: white;
}

.table-data-dict-viewing thead {
    text-transform: capitalize;
}

.table-data-viewing td {
    display: table-cell;
}

.table-data-viewing tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

*,
::after,
::before {
    box-sizing: border-box;
}


/*
.exact-filter {
    font-size: 1rem;
    margin-right: 6px;
    height: 1.2rem;
}*/

@media (max-width: 576px) {
    /* .exact-filter {
        padding-top: 6px;
        font-size: 0.8rem;
        margin-left: 0px;
        margin-right: 3px;
        padding-right: 0px;
        height: 1rem;
    }*/

    .page-control {
        padding-top: 5px;
        font-size: 0.8rem;
        padding-right: 0px;
        margin-right: 0px;
    }
}